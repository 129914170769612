<template>
  <div class="container">
    <v-tabs center-active dark show-arrows>
      <v-tab @click="showStudentsMethod">Ученики</v-tab>
      <v-tab @click="showAdminsMethod">Администраторы</v-tab>
      <v-tab @click="showCompetitionsMethod">Соревнования</v-tab>
      <v-tab @click="showMessagesMethod">Сообщения</v-tab>

    </v-tabs>

    <students v-if="showStudents"/>
    <admins v-if="showAdmins"/>
    <competitions v-if="showCompetitions"/>
    <messages v-if="showMessages"/>

  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  components: {
    Students: () => import('../components/Students'),
    Messages: () => import('../components/Messages'),
    Competitions: () => import('../components/Competitions'),
    Admins: () => import('../components/Admins'),
  },
  data: () => ({
    showStudents: true,
    showCompetitions: false,
    showMessages: false,
    showAdmins: false
  }),
  computed: {
    ...mapState(['user'])
  },
  methods: {
    showStudentsMethod() {
      this.showStudents = true;
      this.showCompetitions = false;
      this.showMessages = false;
      this.showAdmins = false
    },
    showCompetitionsMethod() {
      this.showStudents = false;
      this.showCompetitions = true;
      this.showMessages = false;
      this.showAdmins = false
    },
    showMessagesMethod() {
      this.showStudents = false;
      this.showCompetitions = false;
      this.showMessages = true;
      this.showAdmins = false
    },
    showAdminsMethod() {
      this.showStudents = false;
      this.showCompetitions = false;
      this.showMessages = false;
      this.showAdmins = true
    }
  }
}
</script>